import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Products from "./components/Products";
import Pergolas from "./components/Products/Pergolas";
import Doors from "./components/Products/Doors";
import Sliders from "./components/Products/Sliders";
import Partitions from "./components/Products/Partitions";
import Railings from "./components/Products/Railings";
import Gallery from "./components/Gallery";
import Contact from "./components/Contactus";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
// CHANGE IMAGES ON UNIQUE ID TO SPAN FULL AND TECH SPECS FULL WIDTH ACROSS W-7XL
// WHen on system jump directly into it rather than selection screen like systems with multiple
// WE use ELVIAL W77ST AI2_MD (mostly) and W77ST AI2_ID
// CREATE NEW LOGO FOR ASTRA
// BUSINESS CARDS FOR VARWSA


function App() {
  return (
    <div className="App">
      <Navbar />
     
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/pergolas" element={<Pergolas />} />
        <Route path="/products/doors" element={<Doors />} />
        <Route path="/products/sliders" element={<Sliders />} />
        <Route path="/products/partitions" element={<Partitions />} />
        <Route path="/products/railings" element={<Railings />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </ScrollToTop>
     
      
      <Footer />
    </div>
  );
}

export default App;
