
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/g8.png";

import NavLinks from "./NavLinks";
const Navbar = () => {
  const [open, setOpen] = useState(false);

  const closeMobileMenu = () => {
    setOpen(false);
  };

  return (
    <nav className=" ">
      <div className="flex items-center font-medium justify-end md:pr-12 ">
        <div className="z-50 p-4 md:w-auto mr-auto w-full flex justify-between ">
          <Link to="/">
          <img src={Logo} alt="logo" className="md:cursor-pointer  h-14" />
          </Link>
          <div
  className="text-3xl md:hidden"
  onClick={() => setOpen(!open)}
  style={{ color: 'white' }} // Replace 'yourDesiredColor' with the color you want
>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 text-[#607261] font-[Poppins] ">
          <li>
            <Link to="/" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black"
           >
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black " >
              About
            </Link>
          </li>
          <Link to="/products" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black " >
              Products
            </Link>
          <li>
            <Link to="/gallery" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black">
              Gallery
            </Link>
          </li>
          {/* <li>
            <Link to="/blog" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black" >
              Blog
            </Link>
          </li> */}
          <li>
            <Link to="/contact" className="py-7 px-3 inline-block transform transition duration-259 hover:scale-125  hover:text-black" >
              Contact Us
            </Link>
          </li>
          
        </ul>
        <div className="md:block hidden">
          
        </div>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white z-50 fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"} text-left 
        `}
        >
          <li>
            <Link to="/" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              About
            </Link>
          </li>
          <li>
          <Link to="/products" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              Products
            </Link>
          </li>
          <li>
            <Link to="/gallery" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              Gallery
            </Link>
          </li>
          {/* <li>
            <Link to="/blog" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              Blog
            </Link>
          </li> */}
          <li>
            <Link to="/contact" className="py-7 px-3 inline-block" onClick={() => setOpen(!open)}>
              Contact Us
            </Link>
          </li>
          
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
