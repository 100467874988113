
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./Home.css";
import "./PergolaCarousel"
import Product from ".././assets/gallery/pg4.webp";
import Pap from ".././assets/gallery/papD1.jpeg";
import Partition from ".././assets/gallery/p1.jpg";
import Railing from ".././assets/gallery/r1.jpeg";
import IntroAnimation from "./IntroAnimation.js";
import aboutImg from "./../assets/home/abCover.webp";
import door from "./../assets/home/doors.webp";
import window from "./../assets/home/windows.webp";
import curtainWall from "./../assets/home/curtain.webp";
import projects from "./../assets/home/projects.webp";
import alumil from "./../assets/home/alumil.webp";
import pca from "./../assets/home/Pca.webp";
import etem from "./../assets/home/etem.webp";
import elvial from "./../assets/home/elvial.webp";
import pergola1 from "./../assets/home/pergola1.webp"
import pergola2 from "./../assets/home/pergola2.jpeg"
import pergola3 from "./../assets/home/pergola3.jpeg"
import pergola4 from "./../assets/home/pergola4.jpeg"
import pergola5 from "./../assets/home/pergola5.jpeg"
import Testimonials from "./Testimonials.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMarker } from "@fortawesome/free-solid-svg-icons";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { faPersonThroughWindow } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PergolaCarousel from "./PergolaCarousel";
import barcode from ".././assets/gallery/barcode.jpeg";
import doorCover from ".././assets/gallery/doorCover.jpeg";


const Home = () => {useEffect(() => {
  AOS.init({ duration: 1200 });
}, []);

  return (
    <div className="bg-[#FFFFFF]">
      <main className=" ">
      <IntroAnimation /> {/* Added the IntroAnimation component */}
<div data-aos="fade-up">

<div class="hero bg-cover bg-center flex items-center md:pt-14 flex-col h-screen">
          <h1 class=" text-black text-center font-Lato text-2xl md:text-6xl pt-2 md:pt-12 md:pb-2 font-thin leading-8 tracking-widest uppercase">
            ASTRA CONCEPTS
          </h1>

          <p class="text-[#607261] text-center font-Lato text-lg md:text-3xl font-light leading-8 md:p-5 tracking-narrow md:w-1/4">
            Aluminum Design Systems
          </p>
        </div>

        </div>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full pb-6 md:pb-12">
       
</div>


<div data-aos="fade-up">
<div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full p-12 pb-6 md:pb-12 md:p-36">
  
<div className=" text-black w-full md:w-3/3 md:pt-12 pt-2 p-6 flex flex-col space-y-2 md:p-10 items-start">
            <div data-aos="fade-up">
             <h3 className=" font-normal text-[#607261] text-left md:pb-12 text-lg md:text-4xl tracking-normal">
                About Us
              </h3>
              <p className=" text-left md:text-lg  font-normal  pb-7">
              At Astra Concepts, we're passionate about enhancing your  living experience with our exquisite aluminum pergolas, office partitions, interior doors and more. Our journey began with a simple vision - to transform ordinary  spaces into exceptional, inviting havens where you can unwind, entertain, and create lasting memories.
              </p>
              <Link className="text-left" to="about">
                <button className="bg-[#607261] hover:bg-black text-xs  font-normal text-white py-2 px-4 w-full transform transition duration-500 ">
                  Learn More
                </button>
              </Link>
              </div>
            </div>

  <img src={barcode} alt="Image 2" className="object-cover md:pt-4 w-full h-full" />


</div>
</div>


<div data-aos="fade-up">

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full p-12 pb-6 md:pb-12 md:p-36">

        <img src={Partition} alt="Image 2" className="object-cover md:pt-4 w-full h-full" />

        <div className=" text-black w-full md:w-3/3 md:pt-12 pt-2 p-6 flex flex-col space-y-2 md:p-10 items-start">
            <div data-aos="fade-up">
             <h3 className=" font-normal text-[#607261] text-left md:pb-12 text-lg md:text-4xl tracking-standard">
                What We Do
              </h3>
              <p className=" text-left md:text-lg font-normal pb-7">
              Astra Concepts offers a diverse range of designs, catering to a spectrum of tastes and preferences. Whether you seek a contemporary minimalist look, classic elegance, or something unique, our products are customizable to match your style and complement your space seamlessly.
              </p>
              <Link className="text-left" to="products">
                <button className="bg-[#607261] hover:bg-black text-xs font-normal text-white py-2 px-4 w-full transform transition duration-500 ">
                  View Products
                </button>
              </Link>
              </div>
            </div>


</div>
    </div>

        {/* <div className=" flex flex-col justify-center">
        <div data-aos="fade-up">
          <h3 className="font-normal text-[#607261] text-center md:pb-12 text-lg md:text-4xl tracking-standard md:text-4xl md:pt-32  ">
            Our Partners
          </h3>

          <div className="  flex flex-col justify-center md:pt-2 pt-12 pb-2">
            <div className="  relative flex flex-row md:flex-row md:space-x-5 md:space-y-0 max-w-xl md:max-w-6xl mx-auto  ">
              <div className="  w-full md:3/3 grid place-items-center md:p-20 p-6">
                <img className="object-cover h-full w-1/2" src={alumil} />
              </div>
              <div className=" w-full md:3/3 grid place-items-center md:p-20 p-6">
                <img className="object-cover h-full w-1/2" src={pca} />
              </div>
            
            </div>
          </div>
        </div>
        </div> */}

        <section className=" mt-12">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
              <h2 className=" font-semibold mb-4 md:text-4xl text-lg tracking-tight font-extrabold leading-tight ">
                Contact us to find out more
              </h2>
              <p className=" font-normal mb-6 font-light  md:text-lg">
              At Astra we believe that customer care comes first
              </p>
              <Link className="text-left" to="contact">
                <button className=" bg-[#607261] hover:bg-black text-xs  font-normal text-white py-2 px-4 w-full md:w-2/2 transform transition duration-500 ">
                  GET IN TOUCH
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
